<template>
      <header>          
          <h1>Ben Jerke</h1>           
        
          <h4>Software developer based in Columbus, Ohio </h4>
      </header>
</template>

<script>
export default {
    name: "header-display",
}
</script>

<style scoped>
    /* header{
        display: flex; 
        justify-content: flex-start;
        align-items: flex-end;
        flex-wrap: wrap;
    }
    .break {
        flex-basis: 100%; 
        height: 0;
    }
    @media only screen and (max-width: 768px){
        header{
            display: flex; 
            flex-direction: row;    
        }
        .break{
            display: none;
        }
        h4{
            order: 2;   
            justify-self: flex-end;
        }

    } */
</style>