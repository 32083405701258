<template>
  <div>
      <nav>
          <a href="https://www.linkedin.com/in/benjerke614/">LinkedIn</a>
          <a href="https://github.com/BenJerke">Github</a>
          <a href="BRJ_Resume.pdf" download="">Download My Resume</a>
      </nav>
  </div>
</template>

<script>
export default {
    name: "nav-links", 
}
</script>

<style>
nav{
    display: flex;
    flex-basis: 100%;
    justify-content: space-around;
    align-items: center;
}
</style>